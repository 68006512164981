import React from 'react';

const formatDateAndTime = (dateStr, timeRange) => {
  const date = new Date(dateStr);
  const options = { weekday: 'short', month: 'short', day: 'numeric' };
  const formattedDate = date.toLocaleDateString('en-US', options);
  return `${formattedDate} at ${timeRange}`;
};
const PATH = process.env.REACT_APP_PATH;

const DoctorDetails = ({ details }) => {
  return (
    <div className="container md:w-[60%] w-[100%] mx-auto mb-5 mt-10">
      <div className="grid grid-cols-1 gap-4 border-[1px] border-[#C1C1C1] p-5 rounded-[8px]">
        <div className="flex">
          <div className="mr-5">
            <img
              src={`${PATH}/${details?.image}`}
              alt="Doctor"
              className="w-[100px] h-[100px] border-[1px] rounded-full object-cover border-[#000]"
            />
          </div>
          <div className="border-l-[2px] border-[#000] pl-5">
            <h4 className="text-[24px] font-semibold mb-2">
              {details?.DoctorName}
            </h4>
            <p className="font-medium ">{details?.specialty}</p>
            <p className="mt-2">
              {formatDateAndTime(details?.date, details?.time)}
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorDetails;
