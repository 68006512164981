import {
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import { useTranslation } from 'react-i18next';

const Filter = (props) => {
  const {
    queryAddress,
    fetchAvailability,
    distance,
    setDistance,
    address,
    setAddress,
    specialties,
    languages,
    setSelectedLocation,
    searchSpecialty,
    gender,
    setGender,
    spokenLanguage,
    setSpokenLanguage,
    onSearchSpecialtyChange,
    previousLocation,
    resetSelectedLocation,
    handleReset,
  } = props;
  const { t } = useTranslation();
  const genderMap = {
    M: t('genderOptions.male'),
    F: t('genderOptions.female'),
    O: t('genderOptions.other'),
  };
  const handleSpecialtyChange = (event) => {
    const value = event.target.value;
    onSearchSpecialtyChange(value); // Update the selected specialty
  };
  const handleGenderChange = (event) => {
    const value = event.target.value;
    setGender(value); // Update the selected specialties
  };
  const handleLangChange = (event) => {
    const value = event.target.value;
    setSpokenLanguage(value); // Update the selected specialties
  };
  return (
    <div className="mb-5">
      <div>
        <ReactGoogleAutocomplete
          className="p-2 text-[#000] text-[16px] rounded-[4px] bg-[#fff] font-medium flex-grow border-[1px] border-[#c4c4c4] w-full"
          id="country_cus"
          placeholder={
            previousLocation === 'Current Location'
              ? t('globalSearch.currentLocation')
              : t('globalSearch.enterLocation')
          }
          apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
          onPlaceSelected={(place) => {
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();
            setSelectedLocation({ lat, lng });
            setAddress(place.formatted_address);
          }}
          options={{
            types: ['address'],
          }}
          value={
            address === queryAddress && previousLocation === 'Current Location'
              ? ''
              : address
          }
          onChange={(e) => {
            if (e.target.value === '') {
              resetSelectedLocation();
            }
            setAddress(e.target.value);
          }}
        />
      </div>
      <div className="grid md:grid-cols-4  sm:grid-cols-2 gap-4 my-5">
        <FormControl className="text-[#000] text-[16px] rounded-[4px] bg-[#fff] font-medium border-[1px] w-full">
          <Select
            labelId="specialty-single-select-label"
            id="specialty-single-select"
            value={searchSpecialty} // Keep this as a single value
            onChange={handleSpecialtyChange}
            input={<OutlinedInput />}
            renderValue={
              () =>
                searchSpecialty ? searchSpecialty : t(`globalSearch.specialty`) // Adjusted for single value
            }
            displayEmpty
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 'calc(100vh - 300px)', // Limit the height if necessary
                  width: 'auto', // Auto width
                },
              },
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            }}
          >
            {specialties?.map((specialty) => (
              <MenuItem key={specialty.Id} value={specialty.Name}>
                <ListItemText primary={specialty.Name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className="text-[#000] text-[16px] rounded-[4px] bg-[#fff] font-medium border-[1px]  w-full">
          <Select
            labelId="gender-single-select-label"
            id="gender-single-select"
            value={gender} // Keep this as a single value
            onChange={handleGenderChange}
            input={<OutlinedInput />}
            renderValue={() =>
              gender ? genderMap[gender] : t('globalSearch.gender')
            }
            displayEmpty
          >
            <MenuItem value="M">
              <ListItemText primary={t('genderOptions.male')} />
            </MenuItem>
            <MenuItem value="F">
              <ListItemText primary={t('genderOptions.female')} />
            </MenuItem>
            <MenuItem value="O">
              <ListItemText primary={t('genderOptions.other')} />
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl className="text-[#000] text-[16px] rounded-[4px] bg-[#fff] font-medium border-[1px]  w-full">
          <Select
            labelId="language-single-select-label"
            id="language-single-select"
            value={spokenLanguage} // Keep this as a single value
            onChange={handleLangChange}
            input={<OutlinedInput />}
            renderValue={
              () =>
                spokenLanguage ? spokenLanguage : t('globalSearch.language') // Adjusted for single value
            }
            displayEmpty
          >
            {languages.map((e) => (
              <MenuItem
                key={e?.LanguageNameNative}
                value={e?.LanguageNameNative}
              >
                <ListItemText primary={e?.LanguageNameNative} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className="text-[#000] text-[16px] rounded-[4px] bg-[#fff] font-medium border-[1px]  w-full">
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={distance}
            onChange={(e) => setDistance(e.target.value)}
            input={<OutlinedInput />}
            renderValue={() =>
              distance
                ? t('globalSearch.miles', { distance })
                : t('globalSearch.distance')
            }
            displayEmpty
          >
            <MenuItem key={5} value={5}>
              {t('distanceOptions.fiveMiles')}
            </MenuItem>
            <MenuItem key={10} value={10}>
              {t('distanceOptions.tenMiles')}
            </MenuItem>
            <MenuItem key={25} value={25}>
              {t('distanceOptions.twentyFiveMiles')}
            </MenuItem>
            <MenuItem key={50} value={50}>
              {t('distanceOptions.fiftyMiles')}
            </MenuItem>
          </Select>
        </FormControl>
      </div>
      <button
        onClick={() => fetchAvailability(true)}
        className="mr-3 bg-[#0499A0] disabled:bg-[#9aa5b5] px-4 py-2 hover:bg-[#40b8b1] text-white rounded-[4px] border-2 inline-block text-center border-[#0499A0]"
      >
        {t('buttons.apply')}{' '}
      </button>
      <button
        className=" bg-[#fff] disabled:bg-[#9aa5b5] border-[#0499A0] px-4 py-2 hover:bg-[#40b8b1] text-[#0499A0] rounded-[4px] border-2 inline-block text-center hover:text-[#fff]"
        onClick={handleReset}
      >
        {t('buttons.clear')}
      </button>
    </div>
  );
};
export default Filter;
