/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import { useNavigate } from 'react-router-dom';
import apiClient from 'services/apiClient';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';

const GlobalSearchBox = (props) => {
  const { t } = useTranslation();
  const { searchSpecialty, onSearchSpecialtyChange } = props;
  const navigate = useNavigate();
  const [specialties, setSpecialties] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState('');
  const [selectedLocation, setSelectedLocation] = useState({});
  const [userLocation, setUserLocation] = useState({});
  const [address, setAddress] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentLocation, setCurrentLocation] = useState('');

  const headersObj = {
    Accept: 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  const getSpecialties = async () => {
    try {
      const response = await apiClient.post(
        'MasterApi/GetSpeciality',
        { languageCode: 'en' },
        headersObj
      );
      setSpecialties(response?.data?.Data);
    } catch (err) {
      console.error(err);
    }
  };

  const getLocation = () => {
    setLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ lat: latitude, lng: longitude });

          const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`
          );
          const data = await response.json();
          if (data.results && data.results.length > 0) {
            setAddress(data.results[0].formatted_address);
            setCurrentLocation(data.results[0].formatted_address);
            setSelectedAddress('Current Location');
            setSelectedLocation({ lat: latitude, lng: longitude });
          }
        },
        (err) => {
          toast.error(t('globalSearch.locationError'));
        }
      );
    } else {
      toast.error(t('globalSearch.geolocationUnsupported'));
    }
    setLoading(false);
  };

  useEffect(() => {
    getLocation();
    getSpecialties();
  }, []);

  const handleSearch = () => {
    let lat, lng;

    if (selectedLocation.lat && selectedLocation.lng) {
      lat = selectedLocation.lat;
      lng = selectedLocation.lng;
    } else if (userLocation.lat && userLocation.lng) {
      lat = userLocation.lat;
      lng = userLocation.lng;
    } else {
      toast.error(t('globalSearch.locationSelectError'));
      return null;
    }

    return {
      pathname: '/appointment',
      search: `?specialty=${searchSpecialty}&lat=${lat}&lng=${lng}&address=${address}&selectedAddress=${selectedAddress}`,
    };
  };

  const handleSearchClick = (e) => {
    e.preventDefault();
    const searchPath = handleSearch();
    if (searchPath) {
      navigate(searchPath);
    }
  };

  return (
    <div className="hero-content__search-box">
      <ToastContainer />
      <div className="grid md:grid-cols-2 grid-cols-1 gap-4 mb-0">
        <select
          className="p-4 text-[#000] text-[16px] rounded-[4px] bg-[#fff] font-medium w-full mb-3"
          value={searchSpecialty}
          onChange={(e) => onSearchSpecialtyChange(e.target.value)}
        >
          <option value="" disabled>
            {t('globalSearch.selectSpecialty')}
          </option>
          {specialties?.map((e, index) => (
            <option key={e.value || index} value={e.value}>
              {e.Name}
            </option>
          ))}
        </select>

        <div className="flex mb-3">
          <ReactGoogleAutocomplete
            className="p-4 text-[#000] text-[16px] rounded-[4px] bg-[#fff] font-medium flex-grow"
            apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
            placeholder={
              address === currentLocation || address === ''
                ? t('globalSearch.currentLocation')
                : t('globalSearch.enterLocation')
            }
            onPlaceSelected={(place) => {
              const lat = place.geometry.location.lat();
              const lng = place.geometry.location.lng();
              setSelectedLocation({ lat, lng });
              setSelectedAddress(place.formatted_address);
              setAddress(place.formatted_address);
            }}
            options={{
              types: ['address'],
            }}
            value={address === currentLocation ? '' : address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </div>
      </div>
      <button
        onClick={handleSearchClick}
        className="w-full md:w-full sb__button--secondary bg-[#0499A0] disabled:bg-[#9aa5b5] px-4 py-3 hover:bg-[#40b8b1] text-white rounded-[4px] border-2 block text-center"
        disabled={loading}
      >
        {t('globalSearch.searchButton')}
      </button>
    </div>
  );
};

export default GlobalSearchBox;
