/* eslint-disable react-hooks/exhaustive-deps */
import { faLocationDot, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  APIProvider,
  InfoWindow,
  Map,
  Marker,
} from '@vis.gl/react-google-maps';
import Avatar from 'components/avatar/Avatar';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const PATH = process.env.REACT_APP_PATH;
const API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

const DoctorMap = ({ center, availability, onOpenModal }) => {
  const [positionalArray, setPositionalArray] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({});
  const { t } = useTranslation();
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  const customMarkerIcon = (isSelected) => {
    if (!window.google) return {}; // Guard against undefined google
    return {
      path: window.google.maps.SymbolPath.CIRCLE,
      fillColor: isSelected ? '#577B8D' : '#000000',
      fillOpacity: 1,
      strokeColor: isSelected ? '#000000' : '#80d7db',
      strokeWeight: 6,
      scale: 12,
    };
  };

  useEffect(() => {
    const loadGoogleMapsScript = async () => {
      if (isScriptLoaded || window.google) return; // Already loaded

      try {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places&callback=initMap`;
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        window.initMap = () => {
          setIsScriptLoaded(true);
        };

        script.onerror = () => {
          console.error('Error loading Google Maps API');
        };
      } catch (error) {
        console.error(error);
      }
    };

    loadGoogleMapsScript();
  }, [API_KEY, isScriptLoaded]);

  useEffect(() => {
    const newPositionalArray = [];
    availability.forEach((e) => {
      const existingEntry = newPositionalArray.find(
        (pos) => pos.lat === e.lat && pos.lng === e.lng
      );

      if (existingEntry) {
        existingEntry.data.push(e);
      } else {
        newPositionalArray.push({
          lat: e.lat,
          lng: e.lng,
          data: [e],
        });
      }
    });
    setPositionalArray(newPositionalArray);
  }, [availability]);

  const handleMarkerClick = (details) => {
    setSelectedLocation(details);
  };

  const handleCloseInfoWindow = () => {
    setSelectedLocation({});
  };

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <APIProvider
        apiKey={API_KEY}
        className="map-container"
        language="en"
        region="US"
      >
        <Map
          defaultZoom={8}
          defaultCenter={center}
          gestureHandling={'greedy'}
          heading={0} // Ensure this is a valid number or set appropriately
          disableDefaultUI={true}
        >
          {positionalArray.map((details) => {
            const isSelected =
              details.lat === selectedLocation.lat &&
              details.lng === selectedLocation.lng;
            const label = {
              text: `${details.data.length}`,
              color: '#ffffff',
              fontSize: '16px',
              fontWeight: 'bold',
            };

            return (
              <Marker
                key={`${details.lat}${details.lng}`}
                position={{
                  lat: parseFloat(details.lat),
                  lng: parseFloat(details.lng),
                }}
                icon={customMarkerIcon(isSelected)}
                label={label}
                onClick={() => handleMarkerClick(details)}
              />
            );
          })}
          {selectedLocation.data?.length > 0 && (
            <InfoWindow
              position={{
                lat: parseFloat(selectedLocation.lat),
                lng: parseFloat(selectedLocation.lng),
              }}
              style={{ maxHeight: '400px' }}
              onCloseClick={handleCloseInfoWindow}
            >
              <h5 className="mb-5 bg-[#0499a0] p-3 font-bold text-[20px] text-[#fff]">
                {selectedLocation.data.length} Providers
              </h5>
              {selectedLocation.data.map((doctor, index) => (
                <div
                  key={index}
                  className="w-[100%] mb-5 border-[1px] border-[#000] rounded-[4px] p-[15px]"
                >
                  <div className="">
                    {doctor.TumbImagePath ? (
                      <img
                        src={`${PATH}/${doctor.TumbImagePath}`}
                        alt=" "
                        className="w-[90px] h-[90px] border-[1px] rounded-full object-cover border-[#000] max-w-[90px] block mx-auto mb-3"
                      />
                    ) : (
                      <Avatar />
                    )}
                  </div>
                  <h4 className="text-[16px] font-bold mb-2 text-center mt-5">
                    {doctor.DoctorName}
                  </h4>
                  <p className="font-medium mb-2 text-center">
                    {doctor.Specialities}
                  </p>
                  <span className="text-[#000] py-2 px-5 rounded-[30px] inline-flex text-[14px] mr-5">
                    {doctor.OverallRatingAverage}
                    <FontAwesomeIcon
                      icon={faStar}
                      className="text-[#FED337] w-[20px] ml-3"
                    />
                  </span>
                  <span className="text-[#000] py-2 px-5 rounded-[30px] inline-block text-[14px]">
                    {t('doctor.reviews', { count: doctor.ReviewedUser })}
                  </span>
                  <p className="mb-4">
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      className="bg-white mt-3 inline-block rounded-full mr-2 text-[#0499A0] w-[20px] h-[20px]"
                    />{' '}
                    {doctor.Address}
                  </p>
                  <button
                    onClick={() => onOpenModal(doctor, null)}
                    className="w-full sb__button--secondary bg-[#0499A0] disabled:bg-[#9aa5b5] px-2 py-2 hover:bg-[#40b8b1] font-bold text-base text-white rounded-[4px] border-2 block text-center"
                  >
                    {t('doctor.bookAppointment')}
                  </button>
                </div>
              ))}
            </InfoWindow>
          )}
        </Map>
      </APIProvider>
    </div>
  );
};

export default DoctorMap;
