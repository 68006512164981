/* eslint-disable no-unused-vars */
import GlobalSearchBox from 'components/global-search-box/GlobalSearchbox';
import banner from 'assests/banner.png';
import { Trans, useTranslation } from 'react-i18next';
/**
 * HeroCover Component
 * Renders the hero cover section of the home page.
 * @param {Object} props - The component props.
 * @param {String} props.searchSpecialty - The speciality input value.
 * @param {String} props.country - The number of country input value.
 * @param {String} props.state - The number of state input value.
 * @param {Boolean} props.isDatePickerVisible - The date picker visibility state.
 * @param {Function} props.onLocationChangeInput - The location input change handler.
 * @param {Function} props.onNumGuestsInputChange - The number of guests input change handler.
 * @param {Object} props.dateRange - The date range object.
 * @param {Function} props.onDateChangeHandler - The date change handler.
 * @param {Function} props.onDatePickerIconClick - The date picker icon click handler.
 * @param {Function} props.onSearchButtonAction - The search button click handler.
 * @param {Array} props.locationTypeheadResults - The location typehead results.
 * @param {Function} props.setisDatePickerVisible - The date picker visibility state setter.
 * @returns {JSX.Element} - The HeroCover component.
 */
const HeroCover = (props) => {
  const { t } = useTranslation();
  const { searchSpecialty, onSearchSpecialtyChange } = props;
  return (
    <div
      className="bg-gradient-to-r from-[#119DA3] to-[#DFDFDF]
min-h-[400px] md:min-h-72 lg:min-h-60 text-slate-100"
    >
      <div className="container mx-auto">
        <div className="grid md:grid-cols-2 grid-cols-1 gap-4 items-center">
          <div>
            <h1 className="text-[60px] font-bold mb-10 md:mt-0 mt-8">
              <Trans i18nKey="header.healthMessage">
                Your health is <span className="text-[#1d6aae]">always</span> in
                the first <span className="text-[#1d6aae]">place</span>
              </Trans>
            </h1>
            <GlobalSearchBox
              searchSpecialty={searchSpecialty}
              onSearchSpecialtyChange={onSearchSpecialtyChange}
            />
          </div>
          <div>
            <img src={banner} alt="" className="mx-w-full mx-auto" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroCover;
