import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import user from '../../assests/user.png';
import {
  faAnglesRight,
  faCalendar,
  faCheck,
  faHospital,
  faLanguage,
  faLocationDot,
  faShield,
  faStar,
} from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import health from '../../assests/Health-Checkup.png';
import { Link } from 'react-router-dom';

const Details = () => {
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const [opens, setOpens] = useState(false);
  const onOpenModals = () => setOpens(true);
  const onCloseModals = () => setOpens(false);
  return (
    <>
      <div className="py-12">
        <div className="container mx-auto">
          <div className="grid grid-cols-2 gap-4 bg-[#F0F9F9] p-12 pb-0">
            <div>
              <div className="flex">
                <div className="mr-16">
                  <img src={user} alt=" " />
                </div>
                <div>
                  <h4 className="text-[24px] font-semibold mb-4">
                    Maya Singh (DDS)
                  </h4>
                  <p className="font-medium mb-8">Dentist</p>
                  <span className="bg-[#fff] text-[#000] py-2 px-5 rounded-[30px] inline-flex text-[14px] mr-5">
                    4.3{' '}
                    <FontAwesomeIcon
                      icon={faStar}
                      className="text-[#FED337] w-[20px] ml-3"
                    />
                  </span>
                  <span className="bg-[#fff] text-[#000] py-2 px-5 rounded-[30px] inline-block text-[14px]">
                    20 reviews
                  </span>
                  <p className="mt-8">
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      className="bg-white p-2 w-8 inline-block rounded-full h-8 mr-3 text-[#0499A0]"
                    />{' '}
                    70.8 mi•5301 S Lewis Ave, Tulsa, OK 74105
                  </p>
                </div>
              </div>

              <h2 className="mt-10 text-[20px] font-medium mb-5">
                At a glance
              </h2>
              <div className="flex mb-3 items-center">
                <div className="mr-5">
                  <FontAwesomeIcon
                    icon={faStar}
                    className="bg-[#0499A0] text-[#fff] w-10 p-2 rounded-full h-10"
                  />
                </div>
                <div>
                  <h5 className="font-medium mb-2">4.96 stars</h5>
                  <p>
                    99% are satisfied with this provider
                    <Link to="#" className="underline font-medium ml-2">
                       View 26 ratings
                    </Link>
                  </p>
                </div>
              </div>
              <div className="flex mb-3 items-center">
                <div className="mr-5">
                  <FontAwesomeIcon
                    icon={faHospital}
                    className="bg-[#0499A0] text-[#fff] w-10 p-2 rounded-full h-10"
                  />
                </div>
                <div>
                  <h5 className="font-medium mb-2">
                    Works at Perfect Smile Tulsa
                  </h5>
                  <p>
                    <Link to="#" className="underline font-medium">
                      View practice profile
                    </Link>
                  </p>
                </div>
              </div>
              <div className="flex mb-3 items-center">
                <div className="mr-5">
                  <FontAwesomeIcon
                    icon={faLanguage}
                    className="bg-[#0499A0] text-[#fff] w-10 p-2 rounded-full h-10"
                  />
                </div>
                <div>
                  <h5 className="font-medium mb-2">
                    Speaks English and Spanish
                  </h5>
                </div>
              </div>
              <div className="flex mb-3 items-center">
                <div className="mr-5">
                  <FontAwesomeIcon
                    icon={faCalendar}
                    className="bg-[#0499A0] text-[#fff] w-10 p-2 rounded-full h-10"
                  />
                </div>
                <div>
                  <h5 className="font-medium mb-2">Flexible availability</h5>
                  <p>Offers evening appointments</p>
                </div>
              </div>

              <div className="flex mt-8 border-[2px] border-[#0499a0] p-5 rounded-[8px] w-[fit-content]">
                <div className="mr-5">
                  <h2 className="text-[40px] font-semibold">4.96</h2>
                </div>
                <div className="border-l-[2px]  border-[#0499a0] pl-5">
                  <div className="flex items-center justify-between mb-3">
                    <FontAwesomeIcon
                      icon={faStar}
                      className="text-[#FED337] w-[35px]"
                    />
                    <FontAwesomeIcon
                      icon={faStar}
                      className="text-[#FED337] w-[35px] ml-3"
                    />
                    <FontAwesomeIcon
                      icon={faStar}
                      className="text-[#FED337] w-[35px] ml-3"
                    />
                    <FontAwesomeIcon
                      icon={faStar}
                      className="text-[#FED337] w-[35px] ml-3"
                    />
                    <FontAwesomeIcon
                      icon={faStar}
                      className="text-[#FED337] w-[35px] ml-3"
                    />
                  </div>
                  <p>26 reviews</p>
                </div>
              </div>
              <Link
                to="#"
                className="border-[2px] border-[#0499a0] inline-block mt-5 py-3  px-8 text-[#0499A0] rounded-[8px]"
              >
                Read all reviews
              </Link>

              <h2 className="mt-10 text-[20px] font-medium mb-5">
                Recent reviews
              </h2>
              <div className="mb-5">
                <div className="flex items-center mb-3">
                  <FontAwesomeIcon
                    icon={faStar}
                    className="text-[#FED337] w-[20px]"
                  />
                  <FontAwesomeIcon
                    icon={faStar}
                    className="text-[#FED337] w-[20px] ml-3"
                  />
                  <FontAwesomeIcon
                    icon={faStar}
                    className="text-[#FED337] w-[20px] ml-3"
                  />
                  <FontAwesomeIcon
                    icon={faStar}
                    className="text-[#FED337] w-[20px] ml-3"
                  />
                  <FontAwesomeIcon
                    icon={faStar}
                    className="text-[#FED337] w-[20px] ml-3"
                  />
                </div>
                <p>
                  Very nice dentist office, they addressed all of my questions
                  and concerns with my teeth. Even did extra treatments during
                  my appointment that I didn’t initially book for to get the
                  most out of my appointment. Definitely recommend!
                </p>

                <span className="text-[#9B9B9B] mt-3 inline-block text-[14px]">
                  July 26, 2024 . Madison Z . Verified patient
                </span>
              </div>
              <div className="mb-5">
                <div className="flex items-center mb-3">
                  <FontAwesomeIcon
                    icon={faStar}
                    className="text-[#FED337] w-[20px]"
                  />
                  <FontAwesomeIcon
                    icon={faStar}
                    className="text-[#FED337] w-[20px] ml-3"
                  />
                  <FontAwesomeIcon
                    icon={faStar}
                    className="text-[#FED337] w-[20px] ml-3"
                  />
                  <FontAwesomeIcon
                    icon={faStar}
                    className="text-[#FED337] w-[20px] ml-3"
                  />
                  <FontAwesomeIcon
                    icon={faStar}
                    className="text-[#FED337] w-[20px] ml-3"
                  />
                </div>
                <p>
                  VI have told everyone about Dr. Davis and his Staff! It was
                  the best experience at a Dentist that I have ever had!
                </p>

                <span className="text-[#9B9B9B] mt-3 inline-block text-[14px]">
                  July 26, 2024 . Madison Z . Verified patient
                </span>
              </div>
              <Link to="#" className="underline font-medium mt-5">
                Read all 26 reviews
              </Link>
            </div>
            <div>
              <div className="p-10 bg-white rounded-[12px]">
                <h2 className="mb-1 text-[24px] font-medium">
                  Book an appointment for free
                </h2>
                <p className="text-[#A6A1A1] mb-6">
                  The office partners with PlanetDoctor to schedule appointments
                </p>
                <select className="p-5  text-[#000] text-[16px] border-[1px] border-[#C7C5C5] rounded-[8px] font-medium w-full mb-5">
                  <option value="someOption">Dental Consultation</option>
                  <option value="otherOption">Other option</option>
                </select>
                <div className="flex mb-5 justify-between items-center gap-4">
                  <button className="bg-[#EFEDED] text-[20px] py-4 px-8 text-center  border-[1px] rounded-[8px] w-full">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="mr-3 w-[20px] inline-block"
                    />
                    Dental Consultation
                  </button>
                  <button className="border-[#EFEDED] text-[20px] py-4 px-8 text-center border-[1px] rounded-[8px]  w-full">
                    Dental Consultation
                  </button>
                </div>
                <div className="grid grid-cols-5 gap-4">
                  <div className="text-center bg-[#EBEAE8] text-[#BFBFBF] p-4 rounded-[4px] text-[12px]">
                    <p>
                      Friday,
                      <br />
                      Aug 16
                    </p>
                    <p className="mt-3">
                      No
                      <br />
                      appointment
                    </p>
                  </div>
                  <div className="text-center bg-[#EBEAE8] text-[#BFBFBF] p-4 rounded-[4px] text-[12px]">
                    <p>
                      {' '}
                      Friday,
                      <br />
                      Aug 16
                    </p>
                    <p className="mt-3">
                      No
                      <br />
                      appointment
                    </p>
                  </div>
                  <div className="text-center bg-[#EBEAE8] text-[#BFBFBF] p-4 rounded-[4px] text-[12px]">
                    <p>
                      {' '}
                      Friday,
                      <br />
                      Aug 16
                    </p>
                    <p className="mt-3">
                      No
                      <br />
                      appointment
                    </p>
                  </div>
                  <div
                    className="text-center bg-[#0499A0] text-[#fff] p-4 rounded-[4px] text-[12px]"
                    onClick={onOpenModal}
                  >
                    <p>
                      {' '}
                      Friday,
                      <br />
                      Aug 16
                    </p>
                    <p className="mt-3">
                      No
                      <br />
                      appointment
                    </p>
                  </div>
                  <div className="text-center bg-[#EBEAE8] text-[#BFBFBF] p-4 rounded-[4px] text-[12px]">
                    <p>
                      {' '}
                      Friday,
                      <br />
                      Aug 16
                    </p>
                    <p className="mt-3">
                      No
                      <br />
                      appointment
                    </p>
                  </div>
                  <div
                    className="text-center bg-[#0499A0] text-[#fff] p-4 rounded-[4px] text-[12px]"
                    onClick={onOpenModal}
                  >
                    <p>
                      {' '}
                      Friday,
                      <br />
                      Aug 16
                    </p>
                    <p className="mt-3">
                      No
                      <br />
                      appointment
                    </p>
                  </div>
                  <div className="text-center bg-[#EBEAE8] text-[#BFBFBF] p-4 rounded-[4px] text-[12px]">
                    <p>
                      {' '}
                      Friday,
                      <br />
                      Aug 16
                    </p>
                    <p className="mt-3">
                      No
                      <br />
                      appointment
                    </p>
                  </div>
                  <div
                    className="text-center bg-[#0499A0] text-[#fff] p-4 rounded-[4px] text-[12px]"
                    onClick={onOpenModal}
                  >
                    <p>
                      {' '}
                      Friday,
                      <br />
                      Aug 16
                    </p>
                    <p className="mt-3">
                      No
                      <br />
                      appointment
                    </p>
                  </div>
                  <div className="text-center bg-[#EBEAE8] text-[#BFBFBF] p-4 rounded-[4px] text-[12px]">
                    <p>
                      {' '}
                      Friday,
                      <br />
                      Aug 16
                    </p>
                    <p className="mt-3">
                      No
                      <br />
                      appointment
                    </p>
                  </div>
                  <div className="text-center bg-[#EBEAE8] text-[#BFBFBF] p-4 rounded-[4px] text-[12px]">
                    <p>
                      {' '}
                      Friday,
                      <br />
                      Aug 16
                    </p>
                    <p className="mt-3">
                      No
                      <br />
                      appointment
                    </p>
                  </div>
                  <div className="text-center bg-[#EBEAE8] text-[#BFBFBF] p-4 rounded-[4px] text-[12px]">
                    <p>
                      {' '}
                      Friday,
                      <br />
                      Aug 16
                    </p>
                    <p className="mt-3">
                      No
                      <br />
                      appointment
                    </p>
                  </div>
                  <div className="text-center bg-[#EBEAE8] text-[#BFBFBF] p-4 rounded-[4px] text-[12px]">
                    <p>
                      {' '}
                      Friday,
                      <br />
                      Aug 16
                    </p>
                    <p className="mt-3">
                      No
                      <br />
                      appointment
                    </p>
                  </div>
                  <div className="text-center bg-[#EBEAE8] text-[#BFBFBF] p-4 rounded-[4px] text-[12px]">
                    <p>
                      {' '}
                      Friday,
                      <br />
                      Aug 16
                    </p>
                    <p className="mt-3">
                      No
                      <br />
                      appointment
                    </p>
                  </div>
                  <div
                    className="text-center bg-[#0499A0] text-[#fff] p-4 rounded-[4px] text-[12px]"
                    onClick={onOpenModal}
                  >
                    <p>
                      {' '}
                      Friday,
                      <br />
                      Aug 16
                    </p>
                    <p className="mt-3">
                      No
                      <br />
                      appointment
                    </p>
                  </div>
                </div>
                <div className="flex mt-8 ">
                  <p>
                    Have a question or need help booking an appointment? Call
                    123-456-7890
                  </p>
                  <button className="bg-[#CDEBEC] text-[20px] text-[#0499A0] py-3 px-6 flex items-center justify-between ml-auto  rounded-[8px] w-[70%]">
                    View all availability{' '}
                    <FontAwesomeIcon
                      icon={faAnglesRight}
                      className="ml-3 w-5"
                    />
                  </button>
                </div>
              </div>

              <div className="border-t-[2px] border-[#CCC7C7] mb-5 mt-8">
                <h2 className="py-5 font-semibold text-[24px]">
                  About Dr. Mark M Davis, DDS
                </h2>
                <p>
                  As an expert cosmetic and general dentist, Dr. Davis excels in
                  dental care that focuses on form, function and beauty. At his
                  practice, he operates under the theory that a great smile
                  comes from the inside out. To that end . . .{' '}
                  <Link to="#" className="underline font-medium">
                    show more
                  </Link>
                </p>
              </div>
              <div className="border-t-[2px] border-[#CCC7C7] mb-5 ">
                <h2 className="py-5 font-semibold text-[24px]">
                  Popular visit reasons
                </h2>
                <ul>
                  <li className="mb-3 inline-block w-[50%]">
                    <FontAwesomeIcon icon={faCheck} /> Absorbable Dermal Filler
                  </li>
                  <li className="mb-3 inline-block w-[50%]">
                    <FontAwesomeIcon icon={faCheck} /> Bad breath/Halitosis
                  </li>
                  <li className="mb-3 inline-block w-[50%]">
                    <FontAwesomeIcon icon={faCheck} /> Dental Cleaning
                  </li>
                  <li className="mb-3 inline-block w-[50%]">
                    <FontAwesomeIcon icon={faCheck} /> Pediatric Dentist
                    Consultation
                  </li>
                  <li className="mb-3 inline-block w-[50%]">
                    <FontAwesomeIcon icon={faCheck} /> Amalgam Removal
                    Consultation
                  </li>
                  <li className="mb-3 inline-block w-[50%]">
                    <FontAwesomeIcon icon={faCheck} /> Bridge
                  </li>
                  <li className="mb-3 inline-block w-[50%]">
                    <FontAwesomeIcon icon={faCheck} /> Dental Consultation
                  </li>
                  <li className="mb-3 inline-block w-[50%]">
                    <FontAwesomeIcon icon={faCheck} /> Root Canal
                  </li>
                </ul>
              </div>
              <div className="border-t-[2px] border-[#CCC7C7] mb-5 ">
                <h2 className="py-5 font-semibold text-[24px]">
                  Education and background
                </h2>
                <p className="font-medium mb-3">Specialties</p>
                <ul className="list-inside list-disc mb-5">
                  <li>Dentist</li>
                  <li>Cosmetic Dentist</li>
                </ul>
                <p className="font-medium mb-3">Education and training</p>
                <ul className="list-inside list-disc mb-5">
                  <li>
                    Dental School - University of Oklahoma, Doctor of Dental
                    Surgery
                  </li>
                </ul>
                <p className="font-medium mb-3">Professional memberships</p>
                <ul className="list-inside list-disc mb-5">
                  <li>American Dental Association</li>
                  <li>Oklahoma Dental Association</li>
                  <li>American Academy of Cosmetic Dentistry</li>
                </ul>
                <p className="font-medium mb-3">Awards and publications</p>
                <ul className="list-inside list-disc mb-5">
                  <li>Tulsa People Reader's Choice Cosmetic Dentist 2017.</li>
                  <li>Tulsa People Reader's Choice Cosmetic Dentist 2016.</li>
                  <li>Tulsa People Reader's Choice Cosmetic Dentist 2018.</li>
                </ul>
                <p className="font-medium mb-3">Languages</p>
                <ul className="list-inside list-disc mb-5">
                  <li>English</li>
                  <li>Spanish</li>
                </ul>
                <p className="font-medium mb-3">Provider's gender</p>
                <p className="mb-5">Female</p>
                <p className="font-medium mb-3">NPI number</p>
                <p className="mb-5">1234657890 </p>
              </div>
            </div>
          </div>
          <div className=" bg-[#F0F9F9] px-12 pb-12">
            <div className="border-t-[2px] border-[#CCC7C7] mb-5 ">
              <h2 className="py-5 font-semibold text-[24px]">
                Popular visit reasons{' '}
              </h2>
              <p>
                All reviews have been submitted by patients after seeing the
                provider.
              </p>

              <div className="flex justify-between">
                <div>
                  <p className="font-medium my-3">Specialties</p>
                  <div className="flex items-center mb-3">
                    <FontAwesomeIcon
                      icon={faStar}
                      className="text-[#FED337] w-[20px]"
                    />
                    <FontAwesomeIcon
                      icon={faStar}
                      className="text-[#FED337] w-[20px] ml-3"
                    />
                    <FontAwesomeIcon
                      icon={faStar}
                      className="text-[#FED337] w-[20px] ml-3"
                    />
                    <FontAwesomeIcon
                      icon={faStar}
                      className="text-[#FED337] w-[20px] ml-3"
                    />
                    <FontAwesomeIcon
                      icon={faStar}
                      className="text-[#FED337] w-[20px] ml-3"
                    />
                    <span className="ml-3">4.96</span>
                  </div>
                </div>
                <div>
                  <p className="font-medium my-3">Wait time</p>
                  <div className="flex items-center mb-3">
                    <FontAwesomeIcon
                      icon={faStar}
                      className="text-[#FED337] w-[20px]"
                    />
                    <FontAwesomeIcon
                      icon={faStar}
                      className="text-[#FED337] w-[20px] ml-3"
                    />
                    <FontAwesomeIcon
                      icon={faStar}
                      className="text-[#FED337] w-[20px] ml-3"
                    />
                    <FontAwesomeIcon
                      icon={faStar}
                      className="text-[#FED337] w-[20px] ml-3"
                    />
                    <FontAwesomeIcon
                      icon={faStar}
                      className="text-[#FED337] w-[20px] ml-3"
                    />
                    <span className="ml-3">4.76</span>
                  </div>
                </div>
                <div>
                  <p className="font-medium my-3">Bedside manner</p>
                  <div className="flex items-center mb-3">
                    <FontAwesomeIcon
                      icon={faStar}
                      className="text-[#FED337] w-[20px]"
                    />
                    <FontAwesomeIcon
                      icon={faStar}
                      className="text-[#FED337] w-[20px] ml-3"
                    />
                    <FontAwesomeIcon
                      icon={faStar}
                      className="text-[#FED337] w-[20px] ml-3"
                    />
                    <FontAwesomeIcon
                      icon={faStar}
                      className="text-[#FED337] w-[20px] ml-3"
                    />
                    <FontAwesomeIcon
                      icon={faStar}
                      className="text-[#FED337] w-[20px] ml-3"
                    />
                    <span className="ml-3">4.92</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex p-5 bg-[#E2E6E6] rounded-[8px] items-center mb-5">
              <div className="text-[45px] mr-5">
                <FontAwesomeIcon icon={faShield} />
              </div>
              <div className="">
                <p>
                  Your trust is our top concern, so providers can't pay to alter
                  or remove reviews. We also don't publish reviews that contain
                  any private patient health information <br />
                  <Link to="#" className="underline font-medium">
                    Learn more here
                  </Link>{' '}
                </p>
              </div>
            </div>

            <div className="border-t-[2px] border-[#CCC7C7] mb-5 ">
              <h2 className="py-5 font-semibold text-[24px]">
                Frequently asked questions
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Modal open={open} onClose={onCloseModal} center>
          <h2 className="text-[24px] font-bold px-8 py-4">
            Book an appointment
          </h2>
          <div className="flex bg-[#B4E0E3] py-8  px-8">
            <div className="mr-16">
              <img src={user} alt=" " />
            </div>
            <div>
              <h4 className="text-[24px] font-semibold mb-4">
                Maya Singh (DDS)
              </h4>
              <p className="font-medium mb-8">Dentist</p>
              <span className="bg-[#fff] text-[#000] py-2 px-5 rounded-[30px] inline-flex text-[14px] mr-5">
                4.3{' '}
                <FontAwesomeIcon
                  icon={faStar}
                  className="text-[#FED337] w-[20px] ml-3"
                />
              </span>
              <span className="bg-[#fff] text-[#000] py-2 px-5 rounded-[30px] inline-block text-[14px]">
                20 reviews
              </span>
              <p className="mt-8">
                <FontAwesomeIcon
                  icon={faLocationDot}
                  className="bg-white p-2 w-8 inline-block rounded-full h-8 mr-3 text-[#0499A0]"
                />{' '}
                70.8 mi•5301 S Lewis Ave, Tulsa, OK 74105
              </p>
            </div>
          </div>
          <div className="mt-6 px-8">
            <h3 className="text-[20px] font-medium mb-1">Scheduling details</h3>
            <p>Your selections will help show the right availability</p>
            <div className="relative">
              <select className="py-2 px-8 text-[#000] text-[16px] border-[1px] border-[#C7C5C5] rounded-[4px] font-medium w-full mt-5">
                <option value="someOption">Some option</option>
                <option value="otherOption">Other option</option>
              </select>
              <img
                src={health}
                alt=""
                className="absolute top-[28px] left-[7px] w-[28px]"
              />
            </div>
          </div>
          <div className="mt-6 px-8">
            <h3 className="text-[20px] font-medium mb-1">
              Available appointments
            </h3>
            <p>Click a time to book for free.</p>
          </div>

          <div className="py-4 px-8 bg-[#B4E0E3] mt-5">
            <p className="mb-4 text-[20px]">Mon, Aug 19</p>
            <button className="bg-[#0499A0] text-white p-3 rounded-[4px] mr-3">
              10:00 am
            </button>
            <button className="bg-[#0499A0] text-white p-3 rounded-[4px] mr-3">
              10:00 am
            </button>
          </div>
          <div className="mt-6 px-8">
            <h3 className="text-[20px] font-medium mb-1">
              More availabilities
            </h3>
          </div>
          <div className="py-4 px-8 bg-[#B4E0E3] mt-5">
            <p className="mb-4 text-[20px]">Tue, Aug 20</p>
            <p>No available appointments </p>
          </div>
          <div className="py-4 px-8 bg-[#B4E0E3] mt-3">
            <p className="mb-4 text-[20px]">Tue, Aug 21</p>
            <button
              className="bg-[#0499A0] text-white p-3 rounded-[4px] mr-3"
              onClick={onOpenModals}
            >
              04:00 pm
            </button>
            <button className="bg-[#0499A0] text-white p-3 rounded-[4px] mr-3">
              5:00 pm
            </button>
          </div>
          <div className="py-4 px-8 bg-[#B4E0E3] mt-3">
            <p className="mb-4 text-[20px]">Tue, Aug 22</p>
            <p>No available appointments </p>
          </div>
          <div className="py-4 px-8 bg-[#B4E0E3] mt-3">
            <p className="mb-4 text-[20px]">Tue, Aug 23</p>
            <button className="bg-[#0499A0] text-white p-3 rounded-[4px] mr-3">
              07:00 pm
            </button>
            <button className="bg-[#0499A0] text-white p-3 rounded-[4px] mr-3">
              8:00 pm
            </button>
          </div>
        </Modal>
        <Modal open={opens} onClose={onCloseModals} center>
          <div className="p-12">
            <p className="text-[20px] mb-10">
              This appointment is with a healthcare professional under the
              supervision of Dr. Mark M Davis, DDS. You may not be seen directly
              by Dr. Mark M Davis, DDS.
            </p>
            <Link
              to="/userregister"
              className="bg-[#0499A0] text-white p-2 rounded-[4px] mr-3 block w-full font-medium text-center"
            >
              Continue Booking
            </Link>
            <Link
              to="#"
              className=" border-[1px] border-[#0499A0] text-[#0499A0] p-2 rounded-[4px] mt-5  font-medium block w-full text-center"
            >
              Cancel
            </Link>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Details;
