import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLanguage } from '@fortawesome/free-solid-svg-icons';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@radix-ui/react-dropdown-menu';

/**
 * A component that renders the navigation items for the navbar for both mobile/desktop view.
 *
 * @param {Object} props - The component's props.
 * @param {boolean} props.isAuthenticated - A flag indicating whether the user is authenticated.
 * @param {Function} props.onHamburgerMenuToggle
 */
const NavbarItems = ({ isAuthenticated, onHamburgerMenuToggle }) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  /**
   * Handles the logout action by calling the logout API and updating the authentication state.
   */
  /**
   * Determines if a given path is the current active path.
   *
   * @param {string} path - The path to check.
   * @returns {boolean} - True if the path is active, false otherwise.
   */
  const isActive = (path) => {
    return location.pathname === path;
  };

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('language', lang);
  };

  return (
    <>
      <li className="py-4  px-6 ">
        <Link
          to="/"
          className={` font-semibold text-black hover-underline-animation ${
            isActive('/') && 'active-link'
          }`}
          onClick={onHamburgerMenuToggle}
        >
          {t('navbar.home')}
        </Link>
      </li>
      <li className="py-4  px-6 ">
        <Link
          to="https://planetdoctor.com/about-us"
          className={` font-semibold text-black hover-underline-animation ${
            isActive('https://planetdoctor.com/about-us') && 'active-link'
          }`}
          onClick={onHamburgerMenuToggle}
        >
          {t('navbar.aboutUs')}
        </Link>
      </li>

      <li className="py-4  px-6 ">
        <DropdownMenu>
          <DropdownMenuTrigger
            className={` font-semibold text-black hover-underline-animation ${
              isActive('#') && 'active-link'
            }`}
          >
            {t('navbar.solutions')}
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="dropdown-content"
            style={{ zIndex: '99999' }}
          >
            <DropdownMenuItem
              className="dropdown-item"
              onClick={() =>
                (window.location.href = 'https://planetdoctor.com/for-patient/')
              }
            >
              {t('navbar.forPatient')}
            </DropdownMenuItem>
            <DropdownMenuItem
              className="dropdown-item"
              onClick={() =>
                (window.location.href = 'https://planetdoctor.com/for-doctor/')
              }
            >
              {t('navbar.forDoctor')}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </li>
      <li className="py-4  px-6 relative">
        <Link
          to="https://planetdoctor.ukitss.com/Account/PhysicianRegistrations"
          className={` font-semibold text-black hover-underline-animation ${
            isActive('#') && 'active-link'
          }`}
          onClick={onHamburgerMenuToggle}
        >
          {t('navbar.listPracticePlanetdoctor')}
        </Link>
      </li>
      <li className="py-4  px-6 ">
        <Link
          to="https://planetdoctor.ukitss.com/Account/Login"
          className={` font-semibold text-black hover-underline-animation ${
            isActive('https://planetdoctor.ukitss.com/Account/Login') &&
            'active-link'
          }`}
          onClick={onHamburgerMenuToggle}
        >
          {t('navbar.login')}
        </Link>
      </li>
      <li className="py-4  px-6 ">
        <Link
          to="https://planetdoctor.ukitss.com/Account/PatientRegistrations"
          className={` font-semibold text-black hover-underline-animation ${
            isActive(
              'https://planetdoctor.ukitss.com/Account/PatientRegistrations'
            ) && 'active-link'
          }`}
          onClick={onHamburgerMenuToggle}
        >
          {t('navbar.signup')}
        </Link>
      </li>
      <li className="py-4  px-6 relative">
        <DropdownMenu>
          <DropdownMenuTrigger
            className={` font-semibold text-black hover-underline-animation ${
              isActive('#') && 'active-link'
            }`}
          >
            <FontAwesomeIcon
              icon={faLanguage}
              style={{ color: '#000000', fontSize: 'x-large' }}
            />
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="dropdown-content"
            style={{ zIndex: '99999' }}
          >
            <DropdownMenuItem
              className="dropdown-item"
              onClick={() => handleLanguageChange('en')}
            >
              English
            </DropdownMenuItem>
            <DropdownMenuItem
              className="dropdown-item"
              onClick={() => handleLanguageChange('es')}
            >
              Español
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </li>
    </>
  );
};

export default NavbarItems;
