import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import UserProfile from './routes/user-profile/UserProfile';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import Home from './routes/home/Home';
import { AuthProvider } from './contexts/AuthContext';
import Login from './routes/login/Login';
import AboutUs from './routes/about-us/AboutUs';
import BaseLayout from './routes/layouts/base-layout/BaseLayout';
import BookingConfirmation from 'routes/booking-confimation/BookingConifrmation';
import Appointment from 'routes/appointment/Appointment';
import UserRegister from 'routes/UserRegister/UserRegister';
import Verify from 'routes/Verify/Verify';
import Details from 'routes/Details/Details';
import './i18n';
import BookingFailed from 'routes/booking-confimation/BookingFailed';
// if (process.env.NODE_ENV === 'development') {
//   makeServer();
// }

const router = createBrowserRouter([
  {
    path: '/',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/about-us',
        element: <AboutUs />,
      },
      {
        path: '/user-profile',
        element: <UserProfile />,
      },
      {
        path: '/login',
        element: <Login />,
      },
      {
        path: '/booking-confirmation/:lang',
        element: <BookingConfirmation />,
      },
      {
        path: '/booking-failed/:lang',
        element: <BookingFailed />,
      },
      {
        path: '/appointment',
        element: <Appointment />,
      },
      {
        path: '/userregister',
        element: <UserRegister />,
      },
      {
        path: '/verify',
        element: <Verify />,
      },
      {
        path: '/details',
        element: <Details />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <AuthProvider>
    <RouterProvider router={router} />
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
